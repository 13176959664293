import React from "react"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import MarketBadge from "../components/MarketBadge"
import InvestorReports from "../components/featured/InvestorReports"
import InvByInvestor from "../components/charts/invbyinvestor"
import * as BS from "react-bootstrap"
import "bootstrap/dist/css/bootstrap-grid.css"
import { BreadcrumbLite } from '../components/common/BreadcrumbLite'
import BreadHome from '../components/BreadHome'


const Dashboard = props => {



  const dataArr = []
  const renderInvestor = (edge, index) => {
    dataArr[index] = { name: edge.node.name, investments: edge.node.countUK }
  }


  const dataArr2 = []

  const renderStat = (edge, index) => {
    dataArr2[index] = {
      date: edge.node.dateFormat.split("-").slice(1).reverse().join("/"),
      investments: edge.node.investmentCount,
    }
  }

  

  // console.log(dataArr2)

  const renderLargest = (edge, index) => {
    let i = 100 - index * 3
    let amount = ""
    let currency = ""
    if (edge.node.currency === "£") {
      amount = Math.round((edge.node.totalRaised * 1.3) / 1000000)
      currency = "$"
    } else {
      amount = Math.round(edge.node.totalRaised / 1000000)
      currency = "$"
    }

    let amountToShow = `${new Intl.NumberFormat().format(amount).split(".")}M`
    
    return (
      <BS.ListGroup.Item><BS.Row><BS.Col sm={5}><Link to={`/business/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`} style={{fontSize: 14, color: 'black'}}>{edge.node.name}</Link></BS.Col><BS.Col sm={4}>{" "}<BS.ProgressBar striped style={{backgroundColor: '#1b63af!important'}} now={i} /></BS.Col><BS.Col sm={3}>{currency}{amountToShow}</BS.Col></BS.Row></BS.ListGroup.Item>
    )
  }

  const renderMarkets = (edge, index) => {
    let i = 100 - index * 10
    let amount = Math.round(edge.node.amount/1000000)
    let amountToShow = `${new Intl.NumberFormat().format(amount).split(".")}M`
    let name = edge.node.name.charAt(0).toUpperCase() + edge.node.name.slice(1)

    return (
      <BS.ListGroup.Item><BS.Row><BS.Col sm={5}><Link to={`/market/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`} style={{fontSize: 14, color: 'black'}}>{name}</Link></BS.Col><BS.Col sm={4}>{" "}<BS.ProgressBar striped style={{backgroundColor: '#1b63af!important'}} now={i} /></BS.Col><BS.Col sm={3}>${amountToShow}</BS.Col></BS.Row></BS.ListGroup.Item>
    )
  }

const renderLocations = (edge, index) => {
  let i = 100 - index * 10
  let amount = Math.round(edge.node.amount/1000000)
  let amountToShow = `${new Intl.NumberFormat().format(amount).split(".")}M`
  return (

<BS.ListGroup.Item><BS.Row><BS.Col sm={4}>
  <Link to={`/${edge.node.country.toLowerCase().replace(/ /g, "-")}/${edge.node.city.toLowerCase().replace(/ /g, "-")}/`} style={{fontSize: 14, color: 'black'}}>{edge.node.city}, {edge.node.country}</Link></BS.Col>
  <BS.Col sm={4}>{" "}<BS.ProgressBar striped now={i} style={{background: '#e4234f'}} /></BS.Col><BS.Col sm={4}>${amountToShow}</BS.Col></BS.Row></BS.ListGroup.Item>
  )
}

  const renderPlayer = (edge, index) => {
    const isInvestor_2 = () => {
      if (edge.node.investors[1]) {
        return (
          <span>
            {" "}
            and{" "}
            <Link
              to={`/investor/${edge.node.investors[1]
                .replace(/é/g, "e")
                .toLowerCase()
                .replace(/ /g, "-")}/`}
            >
              {edge.node.investors[1]}
            </Link>
          </span>
        )
      }
    }
    const isInvestor_1 = () => {
      if (edge.node.investors[0]) {
        return (
          <span>
            {" "}
            with{" "}
            <Link
              to={`/investor/${edge.node.investors[0]
                .replace(/é/g, "e")
                .toLowerCase()
                .replace(/ /g, "-")}/`}
            style={{color: 'rgb(33, 37, 41)'}}>
              {edge.node.investors[0]}
            </Link>
          </span>
        )
      }
    }

    const date = new Date(edge.node.date)

    return (
      // <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body>
      //   < Link to={`/business/${edge.node.businessRef.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.businessRef.name}</Link> raised <b>{edge.node.currency}{new Intl.NumberFormat().format(edge.node.amount)}</b> with < Link to={`/investor/${edge.node.investors[0].toLowerCase().replace(/ /g, "-").replace(/é/g, "e")}/`}>{edge.node.investors[0]}</Link> {isInvestor_2()} <MarketBadge market={edge.node.businessRef.what} /><span style={{ fontSize: 12 }}> - {date.toLocaleDateString()}</span>
      // </BS.Card.Body>
      // </BS.Card>
      
      <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}>
        <BS.Card.Body>
          <Link to={`/business/${edge.node.businessRef.name.toLowerCase().replace(/ /g, "-")}/`}>
            {edge.node.businessRef.name}
          </Link>{" "}
          raised{" "}
          <b>
            {edge.node.currency}
            {new Intl.NumberFormat().format(edge.node.amount)}
          </b>
          {isInvestor_1()} {isInvestor_2()}{" "}
          <MarketBadge market={edge.node.businessRef.what} />
          <span style={{ fontSize: 12 }}> - {date.toLocaleDateString()}</span>
        </BS.Card.Body>
      </BS.Card>
    )
  }

  return (
    <section>
      <Helmet>
      <title>UK startup investment data - Who will be the next UK unicorn?</title>
        <meta
          name="title"
          content="UK startup investment data - Who will be the next UK unicorn?"
        ></meta>
        <meta
          name="description"
          content="Get the latest updates from startups, investors and fundraising in your realtime Unicorn Alert dashboard."
        />
      </Helmet>
    
      <BS.Container style={{ paddingLeft: 0, paddingRight: 11, maxWidth: 1240}}>
      <BreadcrumbLite>
      <BreadHome/>
        <BS.Breadcrumb.Item active>Data</BS.Breadcrumb.Item>
      </BreadcrumbLite>

      <h1 style={{marginBottom: 30}}>Investment And Venture Capital Data From UK Startups</h1>
    
        <BS.Row>
        
          {/* <BS.Col sm={8}> */}
    
            {/* <BS.Row style={{display: 'block'}}>
        <BS.Col sm={12}>
        <h1>Don't Miss The Next UK Unicorn!</h1>
        <h2 style={{color: '#34383b', fontWeight: 300, marginBottom: 25}}>Venture Capital And Private Equity Data (live update)!</h2>
        </BS.Col>
      </BS.Row> */}

            {/* <BS.Row>
              <BS.Col sm={4}>
        
                <Link to={"/investment/"}>
                  <BS.Card
                    style={{
                      backgroundColor: "#017bfe",
                      color: "white",
                      marginBottom: 3,
                    }}
                  >
                    <BS.Card.Body>
                      <span style={{ fontSize: 45 }}>
                        {props.data.allFunding.totalCount}
                      </span>{" "}
                      Investments
                    </BS.Card.Body>
                  </BS.Card>
                </Link>
              </BS.Col>
              <BS.Col sm={4}>
                <Link to={"/business/"}>
                  <BS.Card
                    style={{
                      backgroundColor: "#1a62af",
                      color: "white",
                      marginBottom: 3,
                    }}
                  >
                    <BS.Card.Body>
                      <span style={{ fontSize: 45 }}>
                        {props.data.allBusiness.totalCount}
                      </span>{" "}
                      Businesses
                    </BS.Card.Body>
                  </BS.Card>
                </Link>
              </BS.Col>
              <BS.Col sm={4}>
                <Link to={"/investor/"}>
                  <BS.Card
                    style={{
                      backgroundColor: "rgb(90 114 140)",
                      color: "white",
                      marginBottom: 3,
                    }}
                  >
                    <BS.Card.Body>
                      <span style={{ fontSize: 45 }}>
                        {props.data.allInvestor.totalCount}
                      </span>{" "}
                      Investors
                    </BS.Card.Body>
                  </BS.Card>
                </Link>
              </BS.Col>
            </BS.Row> */}
           
            {/* <BS.Row> */}
              <BS.Col sm={4}>
                <BS.Card style={{ marginTop: 0 }}>
                  <BS.Card.Header>Latest UK investments</BS.Card.Header>
                </BS.Card>
                {props.data.allFunding.edges.map(renderPlayer)}
              </BS.Col>

             
            {/* </BS.Row> */}
         
          <BS.Col sm={4}>
            <BS.Card>
              <BS.Card.Header>Investments by location</BS.Card.Header>
              <BS.ListGroup variant="flush">
              {props.data.allLocation.edges.map(renderLocations)}
                
              </BS.ListGroup>
            </BS.Card>

            <BS.Card style={{ marginTop: 27 }}>
              <BS.Card.Header>Investments by market</BS.Card.Header>
              <BS.ListGroup variant="flush">
              {props.data.allCategory.edges.map(renderMarkets)}
                
              </BS.ListGroup>
            </BS.Card>

            <BS.Card style={{ marginTop: 27 }}>
              <BS.Card.Header>Largest total investments </BS.Card.Header>
              <BS.ListGroup variant="flush">
                {props.data.allBusiness.edges.map(renderLargest)}
             
              </BS.ListGroup>4
            </BS.Card>

            
          </BS.Col>
          {props.data.allInvestor.edges.map(renderInvestor)}
              {props.data.allStats.edges.map(renderStat)}
              <BS.Col sm={4} style={{ marginTop: -20 }}>
                {/* <TotalInvestment d2={dataArr2.reverse()} /> */}
                <InvByInvestor d={dataArr} />

                <InvestorReports />
              </BS.Col>
        </BS.Row>
      </BS.Container>
  
      

    </section>
   
  )
}

export const query = graphql`
  {
    allFunding(filter: {businessRef: {country: {eq: "UK"}}} , sort: { order: DESC, fields: date }, limit: 28) {
      edges {
        node {
          amount
          date
          investors
          currency
          id
          businessRef {

              id
              name
              website
              what
              icon
              founders
              country
              locations {
                city
                country
              }
         
          }
        }
      }
      totalCount
    }
    allInvestor(filter: {countUK: {gt: 0}}, sort: { order: DESC, fields: countUK }, limit: 10) {
      edges {
        node {
          name
          count
          countUK
        }
      }
      totalCount
    }
    allBusiness(filter: {country: {eq: "UK"}}, sort: { order: DESC, fields: totalRaised }, limit: 10) {
      edges {
        node {
          totalRaised
          name
          currency
          icon
          locations {
            city
            country
          }
        }
      }
      totalCount
    }
    allCategory(sort: { order: DESC, fields: amount }, limit: 6) {
      edges {
        node {
          amount
          howMany
          name
          url
        }
      }
    }
    allLocation(filter: {country: {eq: "UK"}, amount: {gt: 0}} sort: { order: DESC, fields: amount }) {
      edges {
        node {
          amount
          city
          country
          currency
          howMany
        }
      }
    }
    allStats(limit: 10, sort: { order: DESC, fields: date }) {
      edges {
        node {
          amountCount
          date
          dateFormat
          investmentCount
        }
      }
    }
  }
`
export default Dashboard
